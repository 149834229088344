import InputWithLabel from "components/Form/input/InputWithLabel";
import CustomCheckBox from "components/Form/CustomCheckBox";
import { CustomerService } from "services/CustomerService";
import { IProjectTypes } from "interfaces/IProjectTypes";
import { ProjectService } from "services/ProjectService";
import { useCallback, useEffect, useState } from "react";
import { IProjectPageProps, IProjectFormData } from "interfaces/IProject";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { UserService } from "services/UserService";
import CustomButtom from "components/Form/Button";
import { ICustomer } from "interfaces/ICustomer";
import { IUserSelected } from "interfaces/IUser";
import { useHistory } from "react-router-dom";
import Dropdown from "components/Form/Select";
import { useAuth } from "modules/auth/hooks";
import { ModalAddJourney } from "../Components/modalAddJourney";
import { JourneysService } from "services/JourneysService";

import { checkPermission } from "helpers/checkPermission";

export const DataOfProject: React.FC<IProjectPageProps> = ({
  project,
  handleSaveProject,
}) => {
  const data = useAuth();
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const history = useHistory();
  const toast = useToast();

  const projectService = new ProjectService();
  const customerService = new CustomerService();
  const userService = new UserService();
  const journeysService = new JourneysService();

  const [ProjectTypes, setProjectsType] = useState<IProjectTypes[]>([]);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [users, setUsers] = useState<IUserSelected[]>([]);
  const [managersOfJourney, setManagersOfJourney] = useState<
    { id: string; name: string; email: string }[]
  >([]);
  const [journey, setJourney] = useState<
    { id: string; name: string } | undefined
  >({} as { id: string; name: string });
  const [openModalJourney, setOpenModalJourney] = useState(false);

  const risks = [
    { name: "Risco", value: "" },
    { name: "Baixo", value: "baixo" },
    { name: "Médio", value: "médio" },
    { name: "Neutro", value: "neutro" },
    { name: "Alto", value: "alto" },
    { name: "Muito alto", value: "muito alto" },
  ];
  const stepOfProject = [
    "Backlog",
    "Discovery & Assessment",
    "Planejamento",
    "Levantamento Técnico",
    "Desenvolvimento",
    "Teste Integrado",
    "Homologação",
    "Go Live",
    "Finalizado",
    "Suporte Técnico",
    "Alocação",
  ];

  const [formData, setFormData] = useState<IProjectFormData>({
    customer: { id: "", name: "", email: "", segment: "" },
    name: "",
    description: "",
    outcome: "",
    currentStep: "",
    isBlocked: false,
    billable: false,
    risk: "",
    type: { id: "", name: "" },
  });

  const handleSalveJourney = useCallback(
    (journey: { id: string; name: string }) => {
      setJourney(journey);
      setOpenModalJourney(!openModalJourney);
    },
    [openModalJourney],
  );

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    if (name == "customer") {
      const customer = customers.find((x) => x.id == value);
      if (customer && customer.id) {
        setFormData({
          ...formData,
          customer: {
            id: customer.id,
            name: customer.name,
            email: `${customer.email}`,
            segment: `${customer.segment}`,
          },
        });
      }
    } else if (name == "type") {
      // TODO: entender pq options value não retorna undefined
      if (value == "Tipo do projeto") {
        setFormData({
          ...formData,
          type: {
            id: "",
            name: "",
          },
        });
      } else {
        const type = ProjectTypes.find((x) => x.id == value);
        if (type?.id) {
          setFormData({
            ...formData,
            type: {
              id: type.id,
              name: type.name,
            },
          });
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleAccount = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    const updatedManagers = { ...formData.managers };

    if (!value) {
      for (const key in updatedManagers) {
        if (updatedManagers[key].role === "account") {
          delete updatedManagers[key];
        }
      }
    } else {
      const manager = users.find((x) => x.id == value);

      for (const key in updatedManagers) {
        if (updatedManagers[key].role === "account") {
          delete updatedManagers[key];
        }
      }

      if (manager && manager.id) {
        updatedManagers[manager.id] = {
          name: `${manager.firstName} ${manager.lastName}`,
          role: "account",
        };
      }
    }

    setFormData({
      ...formData,
      managers: updatedManagers,
    });
  };

  const handleAccountOwner = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    const updatedManagers = { ...formData.managers };

    if (!value) {
      for (const key in updatedManagers) {
        if (updatedManagers[key].role === "account_owner") {
          delete updatedManagers[key];
        }
      }
    } else {
      const userSelected: any =
        users.find((x) => x.id == value) ||
        managersOfJourney.find((x) => x.id == value);

      for (const key in updatedManagers) {
        if (updatedManagers[key].role === "account_owner") {
          delete updatedManagers[key];
        }
      }

      if (
        userSelected &&
        userSelected.id &&
        !updatedManagers[userSelected.id]
      ) {
        updatedManagers[userSelected.id] = {
          name: userSelected.name
            ? userSelected.name
            : `${userSelected.firstName} ${userSelected.lastName}`,
          role: "account_owner",
        };
      }
    }

    setFormData({
      ...formData,
      managers: updatedManagers,
    });
  };
  const handleCreateOrUpdateProject = useCallback(
    async (dataProject: IProjectFormData) => {
      const hasPermission = checkPermission({
        permission: ["super_admin", "admin", "project.admin", "project.editor"],
        userRole: data.data.encryptedLoggedUserRoles,
      });

      if (!hasPermission) {
        toast({
          title: "Permissão Negada.",
          description: "Usuário não tem permissão para executar essa ação.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
        return undefined;
      }

      setIsLoadingProjects(true);

      const response = await handleSaveProject({
        ...project,
        ...dataProject,
        ...data,
        journey: {
          id: journey?.id ? journey?.id : "",
          name: journey?.name ? journey?.name : "",
        },
      });

      setIsLoadingProjects(false);

      if (response != undefined) history.push("/projects/create/" + response);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.email, history, toast, project, journey],
  );

  const handleGetJourneyById = useCallback(
    async (journey: { id: string; name: string }) => {
      if (journey.id) {
        const response = await journeysService.getJourneyById(journey.id);

        const managers = Object.entries(response?.managers || {}).map(
          ([id, manager]) => ({
            id,
            name: manager.name,
            email: manager.email,
          }),
        );

        setManagersOfJourney(managers);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const handleGetProjectTypes = useCallback(async () => {
    const response = await projectService.getProjectType();
    setProjectsType(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetCustomers = useCallback(async () => {
    const response = await customerService.getAllCustomers();
    setCustomers(response.sort((a, b) => a?.name?.localeCompare(b?.name)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetUsersToManagersOption = useCallback(async () => {
    const responseUsers = await userService.getAllUsersByRoles([
      "account_executive",
    ]);
    setUsers(responseUsers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetProjectTypes();
    handleGetCustomers();
    handleGetUsersToManagersOption();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormData(project);
    setJourney(project.journey);
  }, [project]);

  useEffect(() => {
    if (journey) {
      handleGetJourneyById(journey);
    }
  }, [journey]);

  return (
    <>
      <Flex display={"inline"}>
        <Flex
          flexDir={["column", "column", "row"]}
          maxH={[
            "calc(100vh - 240px)",
            "calc(100vh - 240px)",
            "calc(100vh - 360px)",
          ]}
          overflow={"auto"}
          mt="5"
          gap="4"
          pr="1"
          pt="4"
        >
          <Flex
            flexDir={"column"}
            flex="1"
            justify={"space-between"}
            gap="5"
            maxH="calc(100vh - 300px)"
          >
            <Dropdown
              minW="100%"
              fontSize={["sm"]}
              required={true}
              label="Cliente"
              name="customer"
              onChange={handleInputChange}
            >
              <option key={""} value={undefined}>
                Selecione um cliente
              </option>
              {customers.map((item, key: number | string) => {
                if (item.id == formData.customer.id) {
                  return (
                    <option key={key} value={item.id} selected>
                      {item.name}
                    </option>
                  );
                } else {
                  return (
                    <option key={key} value={item.id}>
                      {item.name}
                    </option>
                  );
                }
              })}
            </Dropdown>

            <InputWithLabel
              label="Segmento da empresa"
              value={formData.customer.segment}
              readOnly
            />

            <InputWithLabel
              label="Nome do projeto"
              name={"name"}
              required={true}
              value={formData?.name}
              onChange={(e) => handleInputChange(e)}
            />
            <InputWithLabel
              label="Jornada"
              value={journey?.name}
              onClick={() => setOpenModalJourney(true)}
              required
            />

            <InputWithLabel
              label="Descrição"
              name={"description"}
              value={formData.description}
              onChange={(e) => handleInputChange(e)}
            />
            <InputWithLabel
              label="Outcome do projeto"
              value={formData.outcome}
              name="outcome"
              onChange={(e) => handleInputChange(e)}
            />
            <Dropdown
              minW="100%"
              fontSize={["sm"]}
              label="Fase do projeto"
              required
              name="currentStep"
              onChange={handleInputChange}
            >
              <option key={0} value={undefined}>
                Fase do projeto
              </option>

              {stepOfProject.map((step, key: number) => {
                if (step == formData.currentStep) {
                  return (
                    <option value={step} key={key} selected>
                      {step}
                    </option>
                  );
                }
                return (
                  <option value={step} key={key}>
                    {step}
                  </option>
                );
              })}
            </Dropdown>

            <CustomCheckBox
              isChecked={formData.isBlocked}
              name="blocked"
              onChange={(e) =>
                setFormData({ ...formData, isBlocked: e.target.checked })
              }
            >
              <Text fontWeight="normal" fontSize={["sm"]}>
                Bloquear lançamentos
              </Text>
            </CustomCheckBox>
          </Flex>

          <Flex flexDir={"column"} flex="1" gap="5">
            <Flex flexDir={"column"} h="100%" gap="5">
              <Dropdown
                minW="100%"
                fontSize={["sm"]}
                label="Technical Account Manager"
                name="managers"
                onChange={handleAccountOwner}
                required
              >
                <option value={undefined}>Responsável Hvar</option>

                {[
                  ...managersOfJourney.filter(
                    (item) =>
                      !(formData?.managers && formData.managers[item.id]),
                  ),

                  ...Object.keys(formData?.managers || {})
                    .filter(
                      (key) =>
                        formData.managers?.[key]?.role !== "account" &&
                        managersOfJourney.find((item) => item.id === key),
                    )
                    .map((key) => ({
                      id: key,
                      name: formData.managers?.[key]?.name,
                    })),
                ]
                  .sort((a, b) => (a?.name || "")?.localeCompare(b?.name || ""))
                  .map((item, key: number) => {
                    const selected =
                      formData?.managers &&
                      formData?.managers[item.id]?.role === "account_owner";

                    return (
                      <option key={key} value={item.id} selected={selected}>
                        {item.name}
                      </option>
                    );
                  })}
              </Dropdown>
              <Dropdown
                minW="100%"
                fontSize={["sm"]}
                label="Account Executive"
                name="managers"
                onChange={handleAccount}
              >
                <option value={undefined}>Account</option>

                {[
                  ...users,

                  ...Object.keys(formData?.managers || {})
                    .filter(
                      (key) => formData.managers?.[key]?.role === "account",
                    )
                    .map((key) => ({
                      id: key,
                      name: formData?.managers && formData?.managers[key]?.name,

                      role: formData?.managers && formData?.managers[key].role,
                    })),
                ]

                  .filter(
                    (item, index, self) =>
                      index === self.findIndex((t) => t.id === item.id),
                  )
                  .map((item: any, key) => {
                    const manager = formData.managers?.[item.id];
                    const isSelected = manager?.role === "account";

                    return (
                      <option key={key} value={item.id} selected={isSelected}>
                        {item?.name || `${item.firstName} ${item.lastName}`}
                      </option>
                    );
                  })}
              </Dropdown>

              <CustomCheckBox
                isChecked={formData?.billable}
                name="billable"
                onChange={(e) =>
                  setFormData({ ...formData, billable: e.target.checked })
                }
              >
                <Text fontWeight="normal" fontSize={["sm"]}>
                  Billable
                </Text>
              </CustomCheckBox>

              <InputWithLabel
                label="Reconhecimento da receita"
                name="revenueRecognition"
                value={formData.revenueRecognition}
                onChange={(e) => handleInputChange(e)}
              />

              <Flex
                gap="4"
                flexDir={["column", "column", "row"]}
                justifyContent={"space-between"}
                align={"center"}
              >
                <Dropdown
                  minW={["100%", "100%", "100%"]}
                  maxW={["100%", "100%", "100%"]}
                  label="Risco"
                  required
                  fontSize={["sm"]}
                  name="risk"
                  onChange={handleInputChange}
                >
                  {risks.map((item: any, key) => {
                    if (item.value == formData.risk) {
                      return (
                        <option value={item.value} key={key} selected>
                          {item.name}
                        </option>
                      );
                    } else {
                      return (
                        <option key={item.name} value={item.value}>
                          {item.name}
                        </option>
                      );
                    }
                  })}
                </Dropdown>

                <Dropdown
                  minW={["100%", "100%", "100%"]}
                  maxW={["100%", "100%", "100%"]}
                  required
                  label="Tipo do projeto"
                  fontSize={["sm"]}
                  name="type"
                  onChange={handleInputChange}
                >
                  <option value={undefined} selected>
                    Tipo do projeto
                  </option>
                  {ProjectTypes.map((item: any, key: number) => {
                    if (item.id == formData.type.id) {
                      // TODO: Copiar map do risk
                      return (
                        <option key={key} value={item.id} selected>
                          {item.name}
                        </option>
                      );
                    }
                    return (
                      <option key={key} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Dropdown>
              </Flex>
            </Flex>

            <Flex align={"center"} justify={"flex-end"}>
              <CustomButtom
                primaryText="Cancelar"
                py="6"
                px="8"
                onClick={() => {
                  history.push("/projects");
                }}
              />
              <CustomButtom
                primaryText="Salvar dados do projeto"
                bg="orange2"
                color="white"
                isLoading={isLoadingProjects}
                _hover={{
                  opacity: 0.8,
                }}
                onClick={async () => {
                  await handleCreateOrUpdateProject(formData);
                }}
                py="6"
                px="10"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <ModalAddJourney
        isOpen={openModalJourney}
        onClose={() => setOpenModalJourney(!openModalJourney)}
        handleSalveJourney={handleSalveJourney}
        selectedJourney={journey}
        setManagersOfJourney={setManagersOfJourney}
      />
    </>
  );
};
